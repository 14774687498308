.a-grid-center.profile > div.left-space {
  vertical-align: middle;
}

.profile-content {
  vertical-align: middle;
}

.a-grid-vertical-align.profile > div.a-span3 {
  width: 26.18%;
}

.a-grid-vertical-align.profile > div.left-space {
  float: none!important;
  display: table-cell!important;
  zoom: 1;
  padding-right: 14px;
}

.profile .a-span3, .profile .span3 {
  width: 23.448%;
}

div.left-space {
  margin-right: 2%;
  float: left;
  min-height: 1px;
  overflow: visible;
}

.a-spacing, .a-ws .a-ws-spacing {
  margin-bottom: 12px!important;
}

.a-box .a-box-inner {
  border-radius: 8px;
  position: relative;
  padding: 14px 18px;
}
.a-padding-medium {
  padding: 14px 18px!important;
}

.a-box .a-box-inner {
    border-radius: 8px;
    position: relative;
    padding: 14px 18px;
}
.a-padding-medium {
    padding: 14px 18px!important;
}

.a-section:last-child {
  margin-bottom: 0;
}
.a-spacing-top-micro.a-button-stack, .a-spacing-top-micro.a-section {
  padding-top: 4px;
  margin-top: 0!important;
}
.profileSectionStyling {
  padding-bottom: 0!important;
}
.a-section {
  margin-bottom: 22px;
}
.a-padding-none {
  padding: 0!important;
}
.a-spacing-top-micro {
  margin-top: 4px!important;
}
.a-spacing-none, .a-ws .a-ws-spacing-none {
  margin-bottom: 0!important;
}

.a-spacing-none, .a-ws .a-ws-spacing-none {
  margin-bottom: 0!important;
}
.a-row {
  width: 100%;
}

.a-section:last-child {
  margin-bottom: 0;
}
.name-edit-section-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
}
.a-section {
  margin-bottom: 22px;
}
.a-spacing-top-extra-large {
  margin-top: 32px!important;
}

.a-break-word, .aok-break-word {
  word-break: break-all;
}
.a-size-large {
  text-rendering: optimizeLegibility;
  font-size: 24px!important;
  line-height: 32px!important;
}

.a-text-bold {
  font-weight: 700!important;
}

h1 {
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
}

h1, h2 {
  padding-bottom: 4px;
}

h1, h2, h3, h4 {
  text-rendering: optimizeLegibility;
}

h1, h2, h3, h4 {
  padding-bottom: 4px;
}

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
}

.name-edit-icon {
  width: 15px;
  margin-left: 5px;
}
img {
  vertical-align: top;
}
img {
  max-width: 100%;
  border: 0;
}

.a-spacing-none, .a-ws .a-ws-spacing-none {
  margin-bottom: 0!important;
}
.a-row {
  width: 100%;
}

h1.a-size-base {
  padding-bottom: 0;
}

.a-spacing-top-base {
  margin-top: 12px!important;
}
.a-box {
  display: block;
  border-radius: 8px;
  background-color: #fff;
}
.a-box, .a-box-group>.a-box.a-color-alternate-background, .a-box-group>.a-box.a-color-offset-background {
  border: 1px #D5D9D9 solid;
}

.a-box .a-box-inner {
  border-radius: 8px;
  position: relative;
  padding: 14px 18px;
}

.a-spacing-small, .a-ws .a-ws-spacing-small {
  margin-bottom: 8px!important;
}
.a-size-medium {
  text-rendering: optimizeLegibility;
}
.a-size-medium {
  font-size: 18px!important;
  line-height: 24px!important;
}
.a-text-bold {
  font-weight: 700!important;
}
h1 {
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
}
h1, h2 {
  padding-bottom: 4px;
}
h1, h2, h3, h4 {
  text-rendering: optimizeLegibility;
}
h1, h2, h3, h4 {
  padding-bottom: 4px;
}
h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
}

.a-spacing-medium, .a-ws .a-ws-spacing-medium {
  margin-bottom: 16px!important;
}
.a-color-secondary, .a-color-tertiary {
  color: #565959!important;
}
p {
  padding: 0;
  margin: 0 0 14px 0;
}

.a-grid-vertical-align.a-row {
  display: table!important;
  table-layout: fixed;
  zoom: 1;
  border-collapse: collapse;
}
.a-row {
  width: 100%;
}

.a-grid-center.a-fixed-left-grid-inner>.a-fixed-left-grid-col, .a-grid-center.a-fixed-right-grid-inner>.a-fixed-right-grid-col, .a-grid-center.a-row>div.a-column {
  vertical-align: middle;
}
.a-grid-vertical-align.a-row>div.a-span11 {
  width: 96.18%;
}
.a-grid-vertical-align.a-row>div.a-column {
  float: none!important;
  display: table-cell!important;
  zoom: 1;
  padding-right: 14px;
}
.a-span6 div.a-span11, .a-ws .a-span6 .a-ws-row .a-ws-span11, .a-ws .a-ws-span6 .a-ws-row .a-ws-span11, .a-ws .a-ws-span6 div.a-span11 {
  width: 91.296%;
}
.a-span6 .a-column, .a-ws .a-span6 .a-column, .a-ws .a-ws-span6 .a-column {
  margin-right: 4%;
  float: left;
  min-height: 1px;
}
.a-row .a-span11, .a-ws .a-row .a-ws-span11 {
  width: 91.448%;
}
.a-ws div.a-column, div.a-column {
  margin-right: 2%;
  float: left;
  min-height: 1px;
  overflow: visible;
}

p:last-child {
  margin-bottom: 0;
}
.a-spacing-mini, .a-ws .a-ws-spacing-mini {
  margin-bottom: 4px!important;
}
.a-text-bold {
  font-weight: 700!important;
}
p {
  padding: 0;
  margin: 0 0 14px 0;
}

.a-grid-vertical-align.a-row>div.a-column.a-span-last {
  padding-right: 0;
}
.a-grid-center.a-fixed-left-grid-inner>.a-fixed-left-grid-col, .a-grid-center.a-fixed-right-grid-inner>.a-fixed-right-grid-col, .a-grid-center.a-row>div.a-column {
  vertical-align: middle;
}
.a-grid-vertical-align.a-row>div.a-span1 {
  width: 8.68%;
}
.a-grid-vertical-align.a-row>div.a-column {
  float: none!important;
  display: table-cell!important;
  zoom: 1;
  padding-right: 14px;
}
.a-span6 div.a-span-last, .a-ws .a-span6 div.a-span-last, .a-ws .a-ws-span6 div.a-span-last {
  margin-right: 0;
  float: right;
}
.a-span6 div.a-span1, .a-ws .a-span6 .a-ws-row .a-ws-span1, .a-ws .a-ws-span6 .a-ws-row .a-ws-span1, .a-ws .a-ws-span6 div.a-span1 {
  width: 4.62933%;
}
.a-ws .a-ws-span12, div.a-column.a-span-last {
  margin-right: 0;
  float: right;
}
.a-span6 .a-column, .a-ws .a-span6 .a-column, .a-ws .a-ws-span6 .a-column {
  margin-right: 4%;
  float: left;
  min-height: 1px;
}
.a-column.a-span-last {
  margin-right: 0;
  float: right;
}
.a-row .a-span1, .a-ws .a-row .a-ws-span1 {
  width: 6.448%;
}
.a-ws div.a-column, div.a-column {
  margin-right: 2%;
  float: left;
  min-height: 1px;
  overflow: visible;
}
.a-text-right {
  text-align: right!important;
}

form {
  margin-bottom: 14px;
}

input, select, textarea {
  -webkit-transition: all .1s linear;
  transition: all .1s linear;
  line-height: 19px;
  color: #0F1111;
}
button, input {
  line-height: normal;
}
button, input, select, textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}
button, input, select, textarea {
  font-family: inherit;
}

.name-edit-icon {
  width: 15px;
  margin-left: 5px;
}
img {
  vertical-align: top;
}

img {
  max-width: 100%;
  border: 0;
}

p:last-child {
  margin-bottom: 0;
}

.a-color-secondary, .a-color-tertiary {
  color: #565959!important;
}
p {
  padding: 0;
  margin: 0 0 14px 0;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}