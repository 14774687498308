.dropdowns{
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  width: 25%;
  text-align: center;

}

#navbarDropdown{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-container {
  background: none;
  border: none;
  text-decoration: none;
  color: #777;
  font-family: 'Playfair Display';
  font-size: inherit;
  cursor: pointer;
  padding: 0;
}

label {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
}

input {
  width: 13px;
  height: 13px;
  padding: 0;
  margin:0;
  vertical-align: bottom;
  position: relative;
  top: -1px;
  overflow: hidden;
}

.slidecontainer {
  width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
}


/* .row-fluid:before {
  clear: both;
}

.row-fluid:before, .row-fluid:after {
  display: table;
  content: "";
  line-height: 0;
}

.row-fluid [class*="span"]:first-child {
  margin-left: 0;
}

.row-fluid .span12 {
  width: 100%;
} */

/* [class*="span"] {
  float: left;
  min-height: 1px;
  margin-left: 20px;
}

.span12 {
  width: 700px;
}

.row-fluid [class*="span"] {
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  margin-left: 2.8571428571429%;
}

.ty-horizontal-product-filters.ty-selected-product-filters {
  margin-bottom: 10px;
}

.ty-horizontal-product-filters.ty-selected-product-filters {
  margin-bottom: 25px;
}

.ty-selected-product-filters {
  border: none;
}

.ty-horizontal-product-filters {
  background: transparent;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  position: relative;
  margin: 0 0 10px;
}

.ty-horizontal-product-filters {
  padding-left: 10px;
  background: #edeff1;
  margin: 0 0 30px;
}

.ty-product-filters__wrapper {
  background: transparent;
}

.ty-product-filters__wrapper {
  background: #edeff1;
}

.ty-sidebox {
  margin-bottom: 44px;
  word-wrap: break-word;
  background: #fff;
}

.ty-sidebox__title {
  background: #f4f4f4;
  padding: 10px 30px 10px 10px;
  cursor: pointer;
  margin: 0;
}

.ty-sidebox__title {
  border-bottom: none;
}

.ty-sidebox__title {
  position: relative;
  padding: 0px 30px 9px 0px;
  border-bottom: 1px solid #edeff1;
}

.ty-sidebox__title-wrapper, .ty-sidebox__title span, .ty-sidebox__title a {
  font-weight: normal;
  font-size: 16px;
  color: #333;
}

.ty-sidebox__title-wrapper, .ty-sidebox__title span, .ty-sidebox__title a {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  color: #a1a6aa;
  padding: 0;
}

.ty-footer-general__header, .ty-sidebox__title-wrapper, .ty-tryon-block__left-part-title, .ty-tryon-block__form-title, input[type="text"] {
  font-family: 'Playfair Display';
}

.ty-icon-up-dir, .ty-icon-right-dir, .ty-icon-down-dir, .ty-icon-left-dir, .ty-icon-star-empty, .ty-icon-th-list, .ty-icon-heart, 
.ty-icon-flight, .ty-icon-ok, .ty-icon-cancel, .ty-icon-cancel-circle, .ty-icon-minus-circle, .ty-icon-right-thin, .ty-icon-cw, 
.ty-icon-left-circle, .ty-icon-right-circle, .ty-icon-help-circle, .ty-icon-back, .ty-icon-products-multicolumns, .ty-icon-print, 
.ty-icon-chat, .ty-icon-article-alt, .ty-icon-docs, .ty-icon-zoom-in, .ty-icon-zoom-out, .ty-icon-down-open, .ty-icon-left-open, 
.ty-icon-right-open, .ty-icon-up-open, .ty-icon-down-micro, .ty-icon-up-micro, .ty-icon-right-open-thin, .ty-icon-left-open-thin, 
.ty-icon-products-without-options, .ty-icon-short-list, .ty-icon-plus-circle, .ty-icon-user, .ty-icon-doc-text, .ty-icon-calendar, 
.ty-icon-chart-bar, .ty-icon-download, .ty-icon-search, .ty-icon-lock, .ty-icon-popup, .ty-icon-plus, .ty-icon-folder, 
.ty-icon-folder-open, .ty-icon-mail, .ty-icon-twitter, .ty-icon-facebook, .ty-icon-file, .ty-icon-trashcan, .ty-icon-d-arrow, 
.ty-icon-bubble, .ty-icon-upload, .ty-icon-cog, .ty-icon-square, .ty-icon-arrow-up-right, .ty-icon-arrow-up-left, 
.ty-icon-arrow-down-left, .ty-icon-arrow-down-right, .ty-icon-arrow-down, .ty-icon-arrow-up, .ty-icon-arrow-left, 
.ty-icon-arrow-right, .ty-icon-star, .ty-icon-star-half, .ty-icon-refresh, .ty-icon-basket, .ty-icon-image, .icon-image, 
.ty-icon-edit, .ty-icon-live-edit, .ty-icon-gift, .ty-icon-ban-circle, .ty-icon-wrench, .ty-icon-feed {
  font-family: glyphs;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.ty-horizontal-product-filters {
  background: transparent;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  position: relative;
  margin: 0 0 10px;
}

.ty-horizontal-product-filters {
  padding-left: 10px;
  background: #edeff1;
  margin: 0 0 30px;
}

.dot1 {
  position: absolute;
  border-radius: 50%;
  background: #959595;
  width: 7px;
  height: 7px;
  top: -4px;
  right: -3px;
}

.ty-product-filters__wrapper {
  background: transparent;
}

.filter-title {
  display: inline-block;
  font-weight: 700;
  padding: 9px 17px 9px 7px;
  text-transform: uppercase;
  word-wrap: break-word;
}

.ty-horizontal-product-filters-dropdown {
  border-right: 1px solid #ddd;
  display: inline-block;
  position: relative;
}

.ty-horizontal-product-filters-dropdown .ty-horizontal-product-filters-dropdown__wrapper {
  text-decoration: none;
}

.ty-horizontal-product-filters-dropdown__wrapper {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #222;
  font-size: 14px;
  padding: 5px 7px 9px 7px;
  display: inline-block;
  color: #34495e;
  cursor: pointer;
}

.ty-horizontal-product-filters-dropdown__icon, .fa-solid, .fa-caret-down {
  font-size: 30px;
  position: relative;
  padding-left: 10px;
  top: 5px;
}

/*Hides the information within each filter*/
.hidden {
  display: none;
  visibility: visible;
}

.ty-horizontal-product-filters-dropdown__content {
  position: absolute;
  z-index: 101;
  border: 1px solid #97a0a5;
  background: #fff;
  min-width: 250px;
}

/*Should have a slider for the price within the Price tag*/
.ty-price-slider {
  padding: 10px;
  margin-bottom: 20px;
  color: #909598;
}

/*
input[type="text"], input[type="password"] {
  height: 32px;
  /*-webkit-appearance: none;*/
/*  border-radius: 0;
}

input[type="text"], input[type="password"], textarea, select {
  padding: 4px 8px;
  border: 1px solid #c2c9d0;
  background: #fff;
  font-family: 'Open Sans',sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type="text"], input[type="password"] {
  height: 32px;
  border-radius: 0;
}

.ty-price-slider__input-text {
  margin: 0 0 5px 0;
  width: 74px;
}

.ty-range-slider {
  position: relative;
  margin: 15px 12px;
  height: 5px;
  border: 1px solid #d0d6db;
  background: #ecf0f1;
  border-right: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

button, input {
  line-height: normal;
}

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

.ty-horizontal-product-filters .ty-range-slider, .ty-horizontal-product-filters .ty-range-slider .ui-slider-range, .ty-horizontal-product-filters .ty-range-slider .ui-slider-handle {
  z-index: 101;
}
.ty-range-slider {
  position: relative;
  margin: 15px 12px;
  height: 5px;
  border: 1px solid #d0d6db;
  background: #ecf0f1;
  border-right: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.ui-corner-all {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.ui-widget, .ui-widget button {
  font-family: 'Open Sans',sans-serif;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
  border-bottom-right-radius: 4px;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
  border-bottom-left-radius: 4px;
}
.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
  border-top-right-radius: 4px;
}
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
  border-top-left-radius: 4px;
}
.ui-widget-content {
  border: 1px solid #aaa;
  background: #fff;
  color: #222;
}
.ui-slider-horizontal {
  height: 0.8em;
}
.ui-slider {
  position: relative;
  text-align: left;
}

element.style {
  left: 0%;
}
.ty-range-slider__item {
  position: absolute;
  top: -5px;
  width: 1px;
  height: 10px;
  background: #d0d6db;
}

.ty-range-slider__num {
  position: absolute;
  top: 21px;
  left: -25px;
  display: block;
  width: 45px;
  text-align: center;
  font-size: 75%;
  cursor: pointer;
  color: #909598;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ty-range-slider__item {
  position: absolute;
  top: -5px;
  width: 1px;
  height: 10px;
  background: #d0d6db;
}

.ty-range-slider__num {
  position: absolute;
  top: 21px;
  left: -25px;
  display: block;
  width: 45px;
  text-align: center;
  font-size: 75%;
  cursor: pointer;
  color: #909598;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


element.style {
  left: 0%;
  width: 100%;
}
.ty-horizontal-product-filters .ty-range-slider, .ty-horizontal-product-filters .ty-range-slider .ui-slider-range, .ty-horizontal-product-filters .ty-range-slider .ui-slider-handle {
  z-index: 101;
}

.ty-range-slider .ui-slider-range {
  background: #d0d6db;
  border: 0px;
  margin-top: -1px;
  height: 7px;
}
.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}
.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background-position: 0 0;
}
.ui-corner-all {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
  border-bottom-right-radius: 4px;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
  border-bottom-left-radius: 4px;
}
.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
  border-top-right-radius: 4px;
}
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
  border-top-left-radius: 4px;
}
.ui-widget-header {
  border: 1px solid #aaa;
  background: #ccc;
  color: #222;
  font-weight: bold;
}

.ty-horizontal-product-filters .ty-range-slider, .ty-horizontal-product-filters .ty-range-slider .ui-slider-range, .ty-horizontal-product-filters .ty-range-slider .ui-slider-handle {
  z-index: 101;
}

.ty-range-slider .ui-slider-handle {
  outline: 0 none;
  -ms-touch-action: none;
  touch-action: none;
}
.ty-range-slider .ui-slider-handle {
  top: -9px;
  margin-left: -8px;
  width: 11px;
  height: 20px;
  background: #34495e;
  border-radius: 0px;
  border: 0px;
  cursor: col-resize;
}
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  border: 1px solid #d3d3d3;
  background: #e6e6e6;
  font-weight: normal;
  color: #555;
}
.ui-slider-horizontal .ui-slider-handle {
  top: -0.3em;
  margin-left: -0.6em;
}
.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 1.2em;
  height: 1.2em;
  cursor: default;
}
.ui-corner-all {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

element.style {
  left: 100%;
}
.ty-horizontal-product-filters .ty-range-slider, .ty-horizontal-product-filters .ty-range-slider .ui-slider-range, .ty-horizontal-product-filters .ty-range-slider .ui-slider-handle {
  z-index: 101;
}

.radio, .checkbox, input[type="radio"], input[type="checkbox"] {
  margin: -4px 6px 0 0;
  padding: 0;
  vertical-align: middle;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.hidden {
  display: none;
}
.hidden {
  display: none;
  visibility: visible;
}
button, input {
  line-height: normal;
}
button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

.ty-horizontal-product-filters-dropdown__content .ty-product-filters__tools {
  background-color: #edeff1;
}
.ty-product-filters__tools {
  padding: 10px 12px;
}

.ty-btn {
  display: inline-block;
  margin-bottom: 0;
  padding: 6px 14px;
  outline: 0px;
  border: 1px solid rgba(0,0,0,0);
  background: #bdc3c7;
  background-image: none;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  line-height: 1.428571429;
  cursor: pointer;
  font-family: 'Open Sans',sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: background 200ms;
  -moz-transition: background 200ms;
  -o-transition: background 200ms;
  transition: background 200ms;
} */

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
}

@media (min-width: 768px) {
  .ty-sidebox .ty-sidebox__title {
    display: none;
  }

  .ty-sidebox__body {
    display: block !important;
}
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
  font-size: 70px;
  margin-top: -150px;
  }
}
  
@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }
  
  .hero-container > p {
    font-size: 30px;
  }
  
  .btn-mobile {
    display: block;
    text-decoration: none;
  }
  
  .btn {
    width: 100%;
  }
}
