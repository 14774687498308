.categories-page-container {
  margin-top: 50px;
  width: 75%;
  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
  background-color: red;

}
 
.container-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start; /* Align .container2 to the left */
}

.container2 {
  width: 50%;
  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
  background-color: green;
  margin: 0;
  /* overflow-y: scroll;
  overflow-x: scroll; */
}

.container3{
  width: 50%;
  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
  background-color: pink;
  margin: 0;
}
 