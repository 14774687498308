.my-custom-btn{
    color: black; 
    background-color: white;   
}


/*What to do when hovering over button*/
.my-custom-btn:hover, #loginBtnReg:hover, #registerBtnReg:hover{
    background-color: white;
    color: black;
    box-shadow: 2px 2px 2px rgb(219, 219, 219);
}

/*Container aroudn entire 'form'*/
.containerSize{
    width: 500px;
}

/*Handles the spacing of container surroudning button*/
#loginBtnContainer{
    padding-bottom: 20px;
}

/*Used to handle border styling for buttons because the ids where not working*/
#loginBtn, #registerBtn{
    border: solid 1px rgb(219, 219, 219);
    border-radius: 10px;
 }
 
 #buttonLinkEdit{
    background: none;
 }

 #loginBtnReg, #registerBtnReg{
    background: none;
    color: black;
    border: solid 1px rgb(219, 219, 219);
    border-radius: 10px;
 }