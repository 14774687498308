.navbar {
    /* background: linear-gradient(90deg,  rgba(250, 208, 91, 0.86) 0%, gold 100%); */
    background: black;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    margin-bottom: 10px;
  }

  .img {
    -webkit-filter: invert(1);
    filter: invert(1);
    object-fit: contain;
    width: 40%;
    height: 40%;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
    font-family: 'Playfair Display';
  }
  
  .navbar-logo {
    color: white;
    justify-self: start;
    margin-left: 0px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    font-family: 'Playfair Display';
    display: flex;
    align-items: left;
    padding-top: 15px;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 100px;
    font-family: 'Playfair Display';
    margin-right: 10px;
    margin-bottom: -20px; /* Add margin-bottom to move each link lower */
  }
  
  .nav-links {
    color: white;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-family: 'Playfair Display';
  }
  
  /* .nav-links:hover {
    border-bottom: 4px solid black;
    transition: all 0.2s ease-out;
  } */

  .shop{
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 40%;
  }

  .navbar-nav{
     margin-left: auto;
  }

  .nav-links:hover{
    padding-bottom: 10px;
    transition: all .5s ease-in;
  }
  
  /* .fa-bars {
    color: #fff;
  } */
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }

  #testDropdown{
    padding-top: 30px;
  }

  #navbarTogglerBtn{
    background: none important!;
    color: rgba(0, 0, 0, 0);
     
  }

  #navbarTogglerBtn:focus{
    outline: none important!;
    border: none important!;
  }

  .navbar-toggler-icon{
    /* background-color: white; */
    color: white;
    margin-left: 100px;
    
  }

  .navbar-toggler{
    border: none;
    margin-left: auto;
  }
  
   @media screen and (max-width: 990px) { /*Switched from 960px to 1080 to fix some errors*/
  /*  .NavbarItems {
      position: relative;
    }
  
 

   .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
   .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }*/
    .dropdowns{
      margin-left: auto;
      margin-right: auto;
      padding: 20px;
      width: 25%;
      text-align: center;
    
    }


    #navbarDropdown{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .navbar-nav{
      padding-top: 10px; /**100px*/
      background-color: black;
      opacity: .75; /**35*/
      padding-bottom: 200px;
    }

 
    #testSpan{
      /* background-color: white; */
      margin-top: 20px;
      /* color: white; */
      /* text-emphasis-color: white; */
      background-color: none;
    }

    .nav-item.dropdown .dropdown-menu{
      width: auto; /* Set a specific width or 'auto' as needed */
      max-width: 20%; /* Ensure it doesn't exceed the screen width */
    }

    #testDropdown{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #titles{
      padding-right: 15px;
    }

    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
      padding-top: 20px;
    }

    #dropDownMobile{
      position: relative;
      left: 0;
      top: 0;
      margin-top: 1rem;
    }
  
  /*  .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }

  .navbar-line {
    border: none;
    border-top: 1px solid #fff;
    margin: 0;
  } */
}

/* @media screen and (max-width: 830px){
  #testDropdown{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5%;
  }
} */

.navbar-nav li.nav-item a.nav-links:hover{
  text-decoration: none;
}

 /* Dropdown Button */
.dropbtn {
  background-color: #3498DB;
  /* color: white; */
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
}

/* The container <div> - needed to position the dropdown content */
/* .dropdown {
  position: relative;
  display: inline-block;
} */

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}
 
#navbarDropdown{
  /* color: white; */
  padding-top: 30px;
  font-weight: lighter;
}

#loginFix{
  color: black;
  font-size: 10px;
  text-align: left;
}

 