.App {
  text-align: left;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: left;
  color: black;
  background-image: white;
}

.auth-form-container, .text-container, .login-form, .register-form {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .auth-form-container {
    padding: 5rem;
    border: none;
    border-radius: 1px;
    margin: 0.5rem;
    font-family:'Playfair Display';
  }
}

@media screen and (min-width: 600px) {
  .mainbody { 
    max-width: 1170px;
    min-width: 280px;
    padding: 5rem;
    border: none;
    border-radius: 1px;
    margin: 0.5rem;
  }
}

.privacy-content {
  display: table;
  content: "";
  line-height: 200%;
}

.privacy-content > div {
  padding-bottom: 40px;
  background-color: white;
  display: block;
}

.privacy-content > p, li {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: normal;
  margin-left: 10px;
}

.privacy-content > h3 {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: normal;
}

.privacy-content > ul {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: normal;
  margin-left: 30px;
}

.row-fluid-content {
  width: 100%;
}

.row-fluid-content {
  display: table;
  content: "";
  line-height: 200%;
}

.return-content > p, h2, h3 {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: normal;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.about-us {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: normal;
}

.column {
  float: left;
  width: 50%;
  padding: 50px;
}

header {
  text-align: left;
  padding: 0.25rem 0;
  font-family:'Playfair Display';
  font-size: 24px;
  height: 60px;
}

label {
  text-align: left;
  padding: 5px;
  font-family:'Playfair Display';
}

input {
  margin: 0.1rem 0;
  padding: 1rem;
  width: 400px;
  border-radius: 1px;
  font-family:'Playfair Display';
}

button {
  border: none;
  background-color: #238FBD;
  padding: 15px;
  font-family:'Playfair Display';
  border-radius: 1px solid black;
  font-size: 16px;
  cursor: pointer;
  color: white;
  width: 200px;
}


.link-btn {
  background: none;
  color: black;
  text-decoration: underline;
}

.link-home-btn {
  background: none;
  color: rgb(0,0,0,0.3);
  text-align: left;
  text-decoration: underline;
}

.text {
  font-family: 'Playfair Display';
  padding: 15px;
}

.img {
  text-align: left;
  height: auto;
}

.captcha {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  width: 150px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 2px solid black;
  width: 150px;
  background-color: white;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.register,
.map {
  display: flex;
  height: 50vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /* background-image: url('/images/trending.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.map {
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  /* background-image: url('/images/trending.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.navbar {
  background-color: #f0f0f0;
  padding: 10px;
}

.navbar-list {
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

.navbar-item {
  margin-right: 10px;
}

.navbar-item:last-child {
  margin-right: 0;
}

.navbar-line {
  border: none;
  border-top: 1px solid #000;
  margin: 0;
}

.hamburger {
  background-color: #2a2d2d;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 42px;
  padding: 9px 4% 2px 4%;
  color: white;
  border: 0;
  font-size: 1.4em;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  z-index: 10000000000000;
}

/* Dropdown Button */
.dropbtn {
  background-color: black;
  color: white;
  padding: 16px;
  font-size: 20px;
  border-color: white;
  font-family: 'Playfair Display';
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #238FBD;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  border-radius: 1px solid white;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: black;
  min-width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}
