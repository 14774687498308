.row-fluid [class*="span"]:first-child {
    margin-left: 0;
}

.row-fluid {
    width: 100%;
}
.row-fluid .span12 {
    width: 100%;
}
.row-fluid [class*="span"] {
    display: block;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 2.8571428571429%;
}
.span12 {
    width: 700px;
}
[class*="span"] {
    float: left;
    min-height: 1px;
    margin-left: 20px;
}

body, p, div, li, a, input[type="text"], input[type="password"], textarea, select {
    font-family: 'PT Sans';
}
body, p, div, li {
    color: #333;
    font-family: 'Playfair Display';
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
}

.product-breadcrumbs {
    padding-bottom: 0px;
    margin-bottom: 5px;
}
.ty-breadcrumbs {
    position: relative;
    margin-bottom: 14px;
    padding: 20px 0px 10px 0px;
    color: #fff;
}
.ty-breadcrumbs {
    position: relative;
    margin-bottom: 14px;
    padding: 20px 105px 10px 0px;
    color: #fff;
}

.ty-breadcrumbs .inner-bcr {
    padding: 0px 105px 0px 0px;
}

.ty-breadcrumbs__a {
    color: #a5afb9;
}

.ty-breadcrumbs__arrow, .ty-breadcrumbs__a, .ty-breadcrumbs__current {
    display: inline;
    padding: 2px;
}
a {
    font-size: 12px;
    color: #36c;
}

.ty-breadcrumbs__slash {
    display: inline-block;
    margin: 0px 6px;
    color: #a5afb9;
}

.ty-breadcrumbs__a {
    color: #a5afb9;
}
.ty-breadcrumbs__arrow, .ty-breadcrumbs__a, .ty-breadcrumbs__current {
    display: inline;
    padding: 2px;
}

a {
    font-size: 12px;
    color: #36c;
}

.ty-breadcrumbs__slash {
    display: inline-block;
    margin: 0px 6px;
    color: #a5afb9;
}

.ty-breadcrumbs__current {
    color: #36c;
}
.ty-breadcrumbs__current {
    color: #1abc9c;
}

.ty-breadcrumbs__arrow, .ty-breadcrumbs__a, .ty-breadcrumbs__current {
    display: inline;
    padding: 2px;
}

.ty-breadcrumbs .inner-bcr {
    padding: 0px 105px 0px 0px;
}

.product-h1 {
    margin-top: 15px;
    font-size: 26px;
    font-weight: 400;
    padding-bottom: 15px;
    margin-bottom: 0px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Playfair Display';
}
h1, h2 {
    text-decoration: none;
    font-weight: bold;
    font-style: normal;
    font-size: 20px;
    font-family: 'Open Sans',sans-serif;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0 0 15px 0;
    color: #333;
}
h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

.ty-product-switcher {
    position: absolute;
    top: 25px;
    right: 0;
    padding: 0 24px;
}

.ty-product-switcher__a.disabled, .ty-product-switcher__a.disabled .ty-product-switcher__icon, .ty-product-switcher__a.disabled:hover .ty-product-switcher__icon {
    background: none;
    color: #d0d6db;
    cursor: default;
}
.ty-product-switcher__a-left {
    float: left;
    left: 0;
}
.ty-product-switcher__a {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    top: -2px;
    padding: 0;
    font-size: 18px;
}

.ty-product-switcher__a.disabled, .ty-product-switcher__a.disabled .ty-product-switcher__icon, .ty-product-switcher__a.disabled:hover .ty-product-switcher__icon {
    background: none;
    color: #d0d6db;
    cursor: default;
}

.ty-product-switcher__icon {
    color: #97a3ae;
}
.ty-icon-up-dir, .ty-icon-right-dir, .ty-icon-down-dir, .ty-icon-left-dir, .ty-icon-star-empty, .ty-icon-th-list, .ty-icon-heart, .ty-icon-flight, .ty-icon-ok, .ty-icon-cancel, .ty-icon-cancel-circle, .ty-icon-minus-circle, .ty-icon-right-thin, .ty-icon-cw, .ty-icon-left-circle, .ty-icon-right-circle, .ty-icon-help-circle, .ty-icon-back, .ty-icon-products-multicolumns, .ty-icon-print, .ty-icon-chat, .ty-icon-article-alt, .ty-icon-docs, .ty-icon-zoom-in, .ty-icon-zoom-out, .ty-icon-down-open, .ty-icon-left-open, .ty-icon-right-open, .ty-icon-up-open, .ty-icon-down-micro, .ty-icon-up-micro, .ty-icon-right-open-thin, .ty-icon-left-open-thin, .ty-icon-products-without-options, .ty-icon-short-list, .ty-icon-plus-circle, .ty-icon-user, .ty-icon-doc-text, .ty-icon-calendar, .ty-icon-chart-bar, .ty-icon-download, .ty-icon-search, .ty-icon-lock, .ty-icon-popup, .ty-icon-plus, .ty-icon-folder, .ty-icon-folder-open, .ty-icon-mail, .ty-icon-twitter, .ty-icon-facebook, .ty-icon-file, .ty-icon-trashcan, .ty-icon-d-arrow, .ty-icon-bubble, .ty-icon-upload, .ty-icon-cog, .ty-icon-square, .ty-icon-arrow-up-right, .ty-icon-arrow-up-left, .ty-icon-arrow-down-left, .ty-icon-arrow-down-right, .ty-icon-arrow-down, .ty-icon-arrow-up, .ty-icon-arrow-left, .ty-icon-arrow-right, .ty-icon-star, .ty-icon-star-half, .ty-icon-refresh, .ty-icon-basket, .ty-icon-image, .icon-image, .ty-icon-edit, .ty-icon-live-edit, .ty-icon-gift, .ty-icon-ban-circle, .ty-icon-wrench, .ty-icon-feed {
    font-family: glyphs;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}
i, em, dfn {
    font-style: italic;
}

.ty-product-switcher span {
    font-size: 85%;
    padding: 2px;
    color: #d0d6db;
}

.ty-product-switcher__a-right {
    float: right;
    right: 0;
}

.ty-product-switcher__a {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    top: -2px;
    padding: 0;
    font-size: 18px;
}
a {
    font-size: 12px;
    color: #36c;
}

.ty-product-switcher__icon {
    color: #97a3ae;
}

.ty-control-group {
    margin: 0 0 12px 0;
    vertical-align: middle;
}

.ty-product-feature {
    padding: 10px;
}

.ty-product-switcher__a-left {
    float: left;
    left: 0;
}
.ty-product-switcher__a {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    top: -2px;
    padding: 0;
    font-size: 18px;
}
a {
    font-size: 12px;
    color: #36c;
}
body, p, div, li, a, input[type="text"], input[type="password"], textarea, select {
    font-family: 'PT Sans';
}
a {
    font-family: 'Open Sans',sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    outline: none;
    color: #1abc9c;
    cursor: pointer;
    border-bottom: 0;
    -webkit-transition: all 150ms;
    -moz-transition: all 150ms;
    -o-transition: all 150ms;
    transition: all 150ms;
}
a {
    background: transparent;
}

.ty-product-switcher__icon {
    color: #97a3ae;
}

.ty-product-block {
    margin-top: 0px;
}
.ty-product-block {
    margin: 5px 0 30px 0;
    margin-top: 5px;
    position: relative;
}

.ty-product-block__img .ty-discount-label {
    position: absolute;
    right: 0;
    top: 80% !important;
    bottom: 0;
    height: 28px;
    background: #c00;
    color: white;
    list-style: none;
}
.ty-product-block .ty-discount-label {
    z-index: 1;
}
.ty-discount-label {
    top: 5px;
    background: #c00;
}
.ty-discount-label {
    position: absolute;
    right: 0;
    top: 0;
    background: #f90;
    color: white;
    list-style: none;
}

.ty-discount-label__item {
    float: left;
    height: 28px;
    font-size: 13px;
    white-space: nowrap;
    line-height: 28px;
    padding: 0 10px 0 12px;
    color: #fff;
}

.ty-inline-block {
    display: inline-block;
}

element.style {
    opacity: 1;
    display: block;
    visibility: visible;
    position: relative;
    width: 42px;
}
.owl-carousel {
    width: 100%;
}

.ty-float-left {
    float: left;
}

.ty-product-thumbnails__item.active {
    border-color: #888;
}

.ty-product-thumbnails__item {
    display: block;
    float: left;
    margin: 0 5px 5px 0;
    border: 1px solid #edeff1;
}

a {
    font-size: 12px;
    color: #36c;
}



.owl-carousel .owl-wrapper-outer {
    overflow: hidden;
    position: relative;
    width: 100%;
}


.ty-product-thumbnails {
    margin-top: 10px;
    text-align: left;
}

* {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
  }
  
  @media (min-width: 768px) {
    .ty-sidebox .ty-sidebox__title {
      display: none;
    }
  
    .ty-sidebox__body {
      display: block !important;
  }
  }
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
    }
  }
    
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
    
    .hero-container > p {
      font-size: 30px;
    }
    
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
    
    .btn {
      width: 100%;
    }
  }