.cards {
    padding: 4rem;
    background: #fff;
  }
  
.span12 {
  width: 700px;
}

[class*="span"] {
  float:left;
  min-height: 1px;
  margin-left: 20px;
}

body, p, div,li {
  color: black;
  font-size: 15px;
  font-style: normal;
  font-family: 'Playfair Display';
  font-weight: normal;
}

.fixed-width {
  float: none !important;
  margin: 0 auto !important;
  max-width: 1200px;
  min-width: 280px;
}

.promo {
  margin-bottom: 20px;
}

.left-column {
  width: 50%;
  float: left;
  margin-top: 30px;
}

.left {
  font-size: 100%;
  text-align: left;
}

.right-column {
  width: 50%;
  float: right;
  margin-top: 30px;
}

.right-column .promo-banner2 {
  width: 50%;
}

.promo-banner1 {
  float: left;
}

#promo-banner1 {
  width: 50%;
  float: left;
  overflow: hidden;
}

.promo-banner2 {
  float: left;
}

.promo-inner {
  position: relative;
  display: inline-block;
  z-index: 10;
}

.promo-clearfix:after {
  content: "";
  clear: both;
  display: table;
}

.img-left {
  max-width: 33%;
  max-height: 25%;
  height: auto;
  vertical-align: middle;
  font-size: 0;
  border: 0;
}

.img-right {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-size: 0;
  border: 0;
  overflow-clip-margin: content-box;
  overflow: clip;
}

.promo-text1 {
  position: absolute;
  background: rgba(255,255,255,0.8);
  bottom: 5px;
  left: 10px;
  right: 10px;
  height: 40px;
  padding-right: 5px;
  padding-top: 10px;
  transition: .5s;
  text-align: center;
}

.promo-text2 {
  position: absolute;
  background: rgba(255,255,255,0.8);
  bottom: 5px;
  left: 15px;
  right: 20px;
  height: 50px;
  padding-right: 10px;
  padding-top: 20px;
  transition: .5s;
  text-align: center;
}

.row-fluid:before, .row-fluid:after {
  display: table;
  content: "";
  line-height: 0;
}

.row-fluid:after {
  clear: both;
}

.home-content > h3 {
  font-weight: 400;
  font-size: 24px;
  margin: 15px 0;
}

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
}
   
@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}
  
@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}
  
@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}